<template>
  <page>
    <component :is="currentType" :ref="currentType" />
  </page>
</template>

<script>
import ByOs from './component/ByOs.vue'
import watchParamsGetData from '@/mixins/watchParamsGetData'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      currentType: 'ByOs',
      params: {
        model: '',
        groupId: ''
      },

      serviceList: [],
      groupList: [],
      appList: []
    }
  },
  created() {},
  components: {
    ByOs
  },
  methods: {
    async getData() {
      this.$refs[this.currentType].getData()
    }
  }
}
</script>

<style lang="scss" scoped>
.timeFrame,
.picker-wrap {
  display: flex;
  align-items: center;
}
</style>
