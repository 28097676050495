<template>
  <page>
    <div slot="headerLeft">
      <el-select
        clearable
        v-model="params.modelId"
        :placeholder="$t('statisticalManagement.deviceType')"
      >
        <el-option
          v-for="item in modelList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>

      <!-- <el-select clearable v-model="params.groupId" :placeholder="$t('deviceList.deviceGroup')">
        <el-option
          v-for="item in groupList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select> -->
      <GroupCascader :value.sync="params.groupId" />

      <div v-if="currentType === 'ByCount'" class="time-wrap">
        <el-radio-group v-model="xDataType" @change="timeChange">
          <el-radio label="today">{{ $t('adminHomePage.today') }}</el-radio>
          <el-radio label="yesterday">{{ $t('statisticalManagement.yesterday') }}</el-radio>
          <el-radio label="recentSevenDays">{{ $t('statisticalManagement.last7days') }}</el-radio>
          <el-radio label="recentThirtyDays">{{ $t('statisticalManagement.lastMonth') }}</el-radio>
          <el-radio label="custom">{{ $t('statisticalManagement.customTimeRange') }}</el-radio>
        </el-radio-group>

        <div v-if="xDataType === 'custom'" class="picker-wrap">
          <el-date-picker
            class="zhiTime"
            type="date"
            v-model="params.startTime"
            value-format="yyyy-MM-dd"
            :placeholder="$t('startDate')"
            :picker-options="pickerOptionsStart"
            @change="timeOptions"
          />
          <span class="line">—</span>
          <el-date-picker
            class="zhiTime"
            type="date"
            v-model="params.endTime"
            :picker-options="pickerOptionsEnd"
            :placeholder="$t('endDate')"
            value-format="yyyy-MM-dd"
            @focus="timeOptionsEnd"
            ref="endTime"
          />
        </div>
      </div>

      <el-button type="primary" plain @click="getData">{{ $t('query') }}</el-button>
    </div>

    <div class="common-tab">
      <span
        @click="currentType = item.type"
        :class="{ active: currentType === item.type }"
        v-for="item in spanItems"
        :key="item.type"
        class="spanItem"
        >{{ item.name }}</span
      >
    </div>

    <component
      ref="chart"
      :modelId="params.modelId"
      :groupId="params.groupId"
      :xDataType="xDataType"
      :customTime="customTime"
      :is="currentType"
    />
  </page>
</template>

<script>
import ByCount from './component/ByCount.vue'
import ByVersion from './component/ByVersion.vue'
import GroupCascader from '@/pages/index/modules/components/GroupCascader'
import watchParamsGetData from '@/mixins/watchParamsGetData'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      firstIn: true,
      xDataType: 'today',
      currentType: 'ByCount',
      params: {
        modelId: '',
        groupId: '',
        startTime: '',
        endTime: ''
      },
      spanItems: [
        {
          type: 'ByCount',
          name: this.$t('statisticalManagement.byDeviceNumber')
        },
        {
          type: 'ByVersion',
          name: this.$t('statisticalManagement.byFirmwareVersion')
        }
      ],
      groupList: [],
      modelList: [],
      customTime: [],

      pickerOptionsStart: {
        disabledDate(time) {
          var d = new Date()

          return time.getTime() > d.getTime()
        }
      },
      pickerOptionsEnd: {}
    }
  },
  components: {
    ByCount,
    ByVersion,
    GroupCascader
  },
  methods: {
    async getSelectData() {
      let result = await Promise.all([
        // this.$api.deviceManagement.groupList({ pageSize: -1 }),
        this.$api.deviceManagement.modelList({ pageSize: -1 })
      ])

      // this.groupList = result[0].result.list.map(item => {
      //   return {
      //     label: item.name,
      //     value: item.id
      //   }
      // })
      if (result) {
        this.modelList = result[0].result.list.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      } else {
        this.modelList = []
      }
    },
    async getData() {
      if (this.firstIn) {
        try {
          await this.getSelectData()
        } catch (e) {
          console.log(e)
        } finally {
          this.firstIn = false
          this.renderData()
        }
      } else {
        this.renderData()
      }
    },
    async renderData() {
      if (this.currentType === 'ByCount') {
        let time = {}

        if (this.xDataType !== 'custom') {
          time = this.$utils.getStartEndTime(this.xDataType)
        } else {
          if (!this.params.startTime || !this.params.endTime) {
            this.$message.warning(this.$t('statisticalManagement.selectCustomTimeRange'))
            return
          }
        }

        const sendData = JSON.parse(JSON.stringify(this.params))

        sendData.startTime = this.params.startTime || time.startTime
        sendData.endTime = this.params.endTime || time.endTime

        this.$refs.chart.getData(sendData)
      } else {
        this.$refs.chart.getData(this.params)
      }
    },
    timeOptions() {
      this.params.endTime = ''
    },
    timeOptionsEnd() {
      if (this.params.startTime == '' || this.params.startTime == null) {
        this.$refs.endTime.pickerVisible = false
        return this.$message.warning(this.$t('statisticalManagement.entryStartTime'))
      } else {
        this.$refs.endTime.pickerVisible = true
      }
      let that = this
      this.pickerOptionsEnd = {
        disabledDate(time) {
          let dataLast = new Date(that.params.startTime).getTime()
          return (
            //时间戳减去8.64e7 其实和24*60*60*1000一样
            time.getTime() > dataLast + 31 * 24 * 60 * 60 * 1000 ||
            time.getTime() < dataLast - 8.64e7 ||
            time.getTime() > Date.now()
          )
        }
      }
    },

    timeChange(val) {
      if (val !== 'custom') {
        this.xDataType = val
        this.params.startTime = ''
        this.params.endTime = ''
        this.customTime = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.time-wrap,
.picker-wrap {
  display: flex;
  align-items: center;
}
</style>
