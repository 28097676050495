<template>
  <page>
    <div slot="headerLeft">
      <template v-if="currentType === 'ByApp'">
        <el-select clearable v-model="params.packageName" :placeholder="$t('appList.appName')">
          <el-option
            v-for="item in appList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>

        <div class="timeFrame">
          <el-radio-group v-model="timeFrame" @change="timeChange">
            <el-radio label="today">{{ $t('adminHomePage.today') }}</el-radio>
            <el-radio label="yesterday">{{ $t('statisticalManagement.yesterday') }}</el-radio>
            <el-radio label="recentSevenDays">{{ $t('statisticalManagement.last7days') }}</el-radio>
            <el-radio label="recentThirtyDays">{{
              $t('statisticalManagement.lastMonth')
            }}</el-radio>
            <el-radio label="custom">{{ $t('statisticalManagement.customTimeRange') }}</el-radio>
          </el-radio-group>

          <div v-if="timeFrame === 'custom'" class="picker-wrap">
            <el-date-picker
              class="zhiTime"
              type="date"
              v-model="params.startTime"
              value-format="yyyy-MM-dd"
              :placeholder="$t('startDate')"
              :picker-options="pickerOptionsStart"
              @change="timeOptions"
            />
            <span class="line">—</span>
            <el-date-picker
              class="zhiTime"
              type="date"
              v-model="params.endTime"
              :picker-options="pickerOptionsEnd"
              :placeholder="$t('endDate')"
              value-format="yyyy-MM-dd"
              @focus="timeOptionsEnd"
              ref="endTime"
            />
          </div>
        </div>
      </template>

      <template v-else>
        <el-select
          @change="packageNameChange"
          clearable
          v-model="packageName"
          :placeholder="$t('appList.appName')"
        >
          <el-option
            v-for="item in appList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>

        <el-select
          @change="versionChange"
          clearable
          v-model="params.appId"
          :placeholder="$t('appList.appVersion')"
        >
          <el-option
            v-for="item in versionList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>

        <el-select
          v-show="publishIdList.length"
          @change="getData"
          clearable
          v-model="params.publishId"
          :placeholder="$t('groupManagement.time')"
        >
          <el-option
            v-for="item in publishIdList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </template>

      <el-button type="primary" plain @click="getData">{{ $t('query') }}</el-button>
    </div>

    <div class="common-tab">
      <span
        @click="tabChange(item.type)"
        :class="{ active: currentType === item.type }"
        v-for="item in spanItems"
        :key="item.type"
        class="spanItem"
        >{{ item.name }}</span
      >
    </div>

    <component
      :ref="currentType"
      :xDataType="xDataType"
      :packageName="params.packageName"
      :appId="params.appId"
      :publishId="params.publishId"
      :customTime="customTime"
      :is="currentType"
    />
  </page>
</template>

<script>
import ByApp from './component/ByApp.vue'
import ByCount from './component/ByCount.vue'
import watchParamsGetData from '@/mixins/watchParamsGetData'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      firstIn: true,
      xDataType: 'today',
      // currentType: 'ByApp',
      currentType: 'ByCount',
      params: {
        packageName: '',
        startTime: '',
        endTime: '',
        appId: '',
        publishId: ''
      },
      spanItems: [
        {
          type: 'ByCount',
          name: this.$t('customerStatistical.InstallStatistics')
        },
        {
          type: 'ByApp',
          name: this.$t('customerStatistical.InstallTrends')
        }
      ],
      serviceList: [],
      groupList: [],
      appList: [],
      versionList: [],
      publishIdList: [],
      timeFrame: 'today',
      customTime: {},

      pickerOptionsStart: {
        disabledDate(time) {
          var d = new Date()

          return time.getTime() > d.getTime()
        }
      },
      pickerOptionsEnd: {},
      packageName: ''
    }
  },
  components: {
    ByApp,
    ByCount
  },
  methods: {
    tabChange(type) {
      this.currentType = type

      if (type === 'ByCount') {
        this.$nextTick(() => {
          this.packageNameChange(this.packageName)
        })
      }
    },
    async packageNameChange(val, appId) {
      if (!val) {
        this.params.appId = ''
        this.params.publishId = ''
        this.versionList = []
        this.publishIdList = []
        return
      }

      try {
        const res = await this.$api.appManagement.getAppsByPackageName({ packageName: val })

        if (res.result && res.result.length) {
          this.versionList = res.result.map(item => {
            return {
              label: item.versionName,
              value: item.appId
            }
          })

          this.params.appId = this.versionList[0].value
        } else {
          this.params.appId = ''
        }

        await this.versionChange(this.params.appId)
      } catch (e) {
        console.log(e)
      }
    },
    async versionChange(val) {
      if (!val) {
        this.params.publishId = ''
        this.publishIdList = []
        return
      }

      try {
        const res = await this.$api.appManagement.publishListAll({ type: 1, appId: val })

        if (res.result && res.result.length) {
          this.publishIdList = res.result.map(item => {
            return {
              label: this.$utils.formatTime(item.createTime),
              value: item.publishId
            }
          })
          this.params.publishId = this.publishIdList[0].value
          this.$refs.ByCount.getData({ publishId: this.params.publishId })
        } else {
          this.publishIdList = []
          this.params.publishId = ''
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getSelectData() {
      const res = await this.$api.appManagement.allApp({ pageSize: -1 })

      if (res.result && res.result.length) {
        this.appList = res.result.map(item => {
          return {
            label: item.appName,
            value: item.packageName,
            id: item.id
          }
        })
        this.packageName = this.appList[0].value
      } else {
        this.packageName = ''
      }

      this.packageNameChange(this.packageName)
    },
    async getData() {
      if (this.firstIn) {
        try {
          await this.getSelectData()
        } catch (e) {
          console.log(e)
        } finally {
          this.firstIn = false
          if (this.currentType !== 'ByCount') {
            this.renderData()
          }
        }
      } else {
        this.renderData()
      }
    },
    async renderData() {
      let sendData = {}

      if (this.currentType === 'ByApp') {
        let time = {}

        if (this.xDataType !== 'custom') {
          time = this.$utils.getStartEndTime(this.xDataType)
        } else {
          if (!this.params.startTime || !this.params.endTime) {
            this.$message.warning(this.$t('statisticalManagement.selectCustomTimeRange'))
            return
          }
        }

        sendData = {
          startTime: this.params.startTime || time.startTime,
          endTime: this.params.endTime || time.endTime
        }

        this.customTime = sendData

        if (this.params.packageName) {
          sendData.packageName = this.params.packageName
        }
      } else {
        const { appId, publishId } = this.params
        sendData = { appId, publishId }
      }

      this.$refs[this.currentType].getData(sendData)
    },

    timeOptions() {
      this.params.endTime = ''
    },
    timeOptionsEnd() {
      if (this.params.startTime == '' || this.params.startTime == null) {
        this.$refs.endTime.pickerVisible = false
        return this.$message.warning(this.$t('statisticalManagement.entryStartTime'))
      } else {
        this.$refs.endTime.pickerVisible = true
      }
      let that = this
      this.pickerOptionsEnd = {
        disabledDate(time) {
          let dataLast = new Date(that.params.startTime).getTime()
          return (
            //时间戳减去8.64e7 其实和24*60*60*1000一样
            time.getTime() > dataLast + 31 * 24 * 60 * 60 * 1000 ||
            time.getTime() < dataLast - 8.64e7 ||
            time.getTime() > Date.now()
          )
        }
      }
    },

    timeChange(val) {
      if (val !== 'custom') {
        this.xDataType = val
        this.params.startTime = ''
        this.params.endTime = ''
        this.customTime = {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.timeFrame,
.picker-wrap {
  display: flex;
  align-items: center;
}
</style>
