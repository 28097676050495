<template>
  <!-- 客户平台设备分组 级联面板选择 -->
  <el-cascader
    v-model="v"
    :placeholder="$t('deviceModal.selectGrouping')"
    :options="treeData"
    :show-all-levels="false"
    :clearable="clearable"
    :props="{
      children: 'children',
      label: 'name',
      value: 'id',
      leaf: 'isLeaf',
      emitPath: false,
      checkStrictly: true
    }"
    @change="change"
    filterable
  />
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    // 是否禁止选择全部分组
    isDisabledAll: {
      type: Boolean,
      default: true
    },
    // 是否支持清空
    clearable: {
      type: Boolean,
      default: false
    },
    needInitApi: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      treeData: [],
      v: ''
    }
  },
  created() {
    if ((!this.options || this.options.length == 0) && this.needInitApi) {
      this._getGroupTree()
    }
  },
  methods: {
    change(val) {
      this.$emit('update:value', val)
    },
    async _getGroupTree() {
      try {
        this.loading = true
        let res = await this.$api.deviceManagement.getGroupTree({})
        if (res.result) {
          this.treeData = this.recursion([res.result])
        } else {
          this.treeData = []
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    // 递归增加是否是叶子节点标识
    recursion(data) {
      let res = []
      if (Array.isArray(data)) {
        res = data.map(item => {
          item.isLeaf = !item.children || item.children == 0 || item.children === null

          item.disabled = this.isDisabledAll && item.id == 200

          if (!item.children || item.children?.length == 0) {
            item.children = null
          }
          if (item.children?.length > 0) {
            item.children = this.recursion(item.children)
          }
          return item
        })
      }
      return res
    }
  },
  watch: {
    options: {
      handler(newVal, oldVal) {
        this.treeData = this.recursion(newVal)
      },
      immediate: true
    },
    value: {
      handler(newVal, oldVal) {
        if (!this.value) {
          this.v = ''
        } else {
          this.v = this.value
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="sass" scoped></style>
