<template>
  <div class="byVersion" v-loading="loading">
    <div class="echart-wrap">
      <status-bar :currentType="currentType" :chartData="tableData" />
    </div>

    <el-divider>{{ $t('details') }}</el-divider>

    <div class="table-wrap">
      <el-table size="mini" :data="tableData" border height="100%">
        <el-table-column
          show-overflow-tooltip
          prop="xData"
          :label="$t('deviceList.cpuTemperature')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="deviceNumber"
          :label="$t('adminHomePage.deviceTotal')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="ratio"
          :label="$t('statisticalManagement.ratio')"
        />
        <el-table-column :label="$t('operation')">
          <template slot-scope="{ row }">
            <div class="operateRow">
              <el-button type="text" @click="detail(row)">{{ $t('details') }}</el-button>
              <el-button type="text" @click="download(row)">{{ $t('download') }}</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      v-elDragDialog
      append-to-body
      width="600px"
      :title="$t('details')"
      :close-on-click-modal="false"
      :visible.sync="detailVisible"
    >
      <div style="display:flex;justify-content: space-between;margin-bottom:10px">
        <span>{{ $t('deviceList.cpuTemperature') }}: {{ currentTemperature }}</span>
        <span>{{ $t('publishApp.common') }} {{ deviceTotal }} {{ $t('adminHomePage.tower') }}</span>
      </div>
      <el-table :height="400" :data="tableDataDetail" border stripe>
        <el-table-column prop="modelName" :label="$t('deviceList.deviceType')" align="center" />
        <el-table-column
          prop="deviceNumber"
          :label="$t('adminHomePage.deviceTotal')"
          align="center"
        />
        <el-table-column prop="ratio" :label="$t('statisticalManagement.ratio')" align="center" />
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import StatusBar from './StatusBar.vue'

export default {
  props: ['currentType', 'modelId', 'groupId'],
  data() {
    return {
      total: 0,
      deviceTotal: 0,
      detailVisible: false,
      loading: false,
      currentTemperature: '',
      params: {
        pageSize: 50,
        currentPage: 1
      },
      rangeMap: {
        1: '20℃' + this.$t('statisticalManagement.follow'),
        2: '20~60℃',
        3: '60~80℃',
        4: '80℃' + this.$t('statisticalManagement.above')
      },
      tableData: [],
      tableDataDetail: []
    }
  },
  methods: {
    async detail(row) {
      this.currentTemperature = row.xData

      try {
        row.groupId = this.groupId
        row.modelId = this.modelId
        row.type = 1
        const res = await this.$api.statisticalManagement.deviceStatisticByStatus(row)

        if (res.result && res.result.deviceModelStatisticResVoList) {
          this.tableDataDetail = res.result.deviceModelStatisticResVoList
          this.deviceTotal = res.result.deviceTotal
        } else {
          this.tableDataDetail = []
          this.deviceTotal = 0
        }
      } catch (e) {
        console.log(e)
      }

      this.detailVisible = true
    },
    async download(row) {
      row.groupId = this.groupId
      row.modelId = this.modelId
      row.type = 1

      try {
        this.loading = true
        const res = await this.$api.statisticalManagement.exportByStatus(row)

        this.$utils.downFile(res)
      } catch (e) {
        console.log(e)
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 2000)
      }
    },
    async getData() {
      this.loading = true
      try {
        const res = await this.$api.statisticalManagement.deviceStatisticByStatusGroup({
          modelId: this.modelId,
          groupId: this.groupId,
          pageSize: 50,
          type: 1
        })

        this.tableData = ((res && res.result) || []).map(item => {
          item.xData = this.rangeMap[item.range]
          return item
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  },
  components: {
    StatusBar
  }
}
</script>

<style lang="scss" scoped>
.byVersion {
  display: flex;
  flex-direction: column;
  flex: 1;
  .echart-wrap {
    display: flex;
    min-height: 150px;
    height: 22vh;
  }
}
</style>
